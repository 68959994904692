<template>
  <div class="modal-alerta" :class="[modalAtivado, tipoMensagem]" @click="fecharModal">
    <transition mode="out-in" name="fade">
      <div v-if="modal.modalAtivado">
        <p v-html="modal.mensagemModal"></p>
        <div class="modal-fechar">
          <span></span>
          <span></span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    modal() {
      return this.$store.state.modal;
    },
    modalAtivado() {
      return this.modal.modalAtivado ? 'active' : '';
    },
    tipoMensagem() {
      return this.modal.tipoModal;
    },
  },
  methods: {
    fecharModal() {
      this.$store.commit('SET_MODAL', {
        ativado: false,
        mensagem: '',
        tipo: '',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-alerta {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 50px;
  cursor: pointer;
  transition: 0.3s;
  transform: rotateX(90deg);
  opacity: 0;
  background: $cinza-3;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  &.active {
    visibility: visible;
    transform: rotateX(0deg);
    opacity: 1;
  }

  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2em;
  }
}

.modal-fechar {
  position: absolute;
  top: 50%;
  right: 10px;
  overflow: hidden;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: $branco-1;

  span {
    position: absolute;
    height: 3px;
    transition: 0.3s;
    border-radius: 8px;
    background: $cinza-3;

    &:first-child {
      top: calc(50% - 5px);
      left: 0;
      width: 30px;
      transform: translate(0, -50%);
    }

    &:last-child {
      top: calc(50% + 5px);
      left: 0;
      width: 30px;
      transform: translate(0%, -50%);
    }
  }
}

.sucesso {
  background: $sucesso-2;

  p {
    color: $preto-1;
  }

  span {
    background: $sucesso-2;

    &:first-child {
      top: 17px;
      left: 6px;
      width: 8px;
      transform: translate(0%, 0%) rotate(45deg);
    }

    &:last-child {
      top: 14px;
      left: 9px;
      width: 17px;
      transform: translate(0%, 0%) rotate(-45deg);
    }
  }
}

.erro {
  background: $erro-1;

  p {
    color: $branco-1;
  }

  span {
    background: $erro-1;

    &:first-child {
      top: 50%;
      left: 50%;
      width: 20px;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:last-child {
      top: 50%;
      left: 50%;
      width: 20px;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
</style>
